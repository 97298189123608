import React from 'react';
import './App.css';
import ContactPage from './containers/ContactPage';

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)
    }
  }

  render () {
    return <div className="App">
      <header className="App-header">
        <ContactPage isMobile={this.state.isMobile}/>
      </header>
    </div>
  }
}
