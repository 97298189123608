import React from 'react';
import './ContactPage.css';
import github from '../assets/github.json';
import twitter from '../assets/twitter.json';
import linkedin from '../assets/linkedin.json';
import ContactBtn from '../components/ContactBtn';

export default class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      github: {
        name: 'github',
        link: "https://github.com/karinfam",

        defaultOptions: {
          loop: false,
          autoplay: false,
          animationData: github,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          },
        },
      },
      twitter: {
        name: 'twitter',
        link: "https://twitter.com/killuasugoi",

        defaultOptions: {
          loop: false,
          autoplay: false,
          animationData: twitter,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          },
        },
      },
      linkedin: {
        name: 'linkedin',
        link: "https://my.linkedin.com/in/karinfam",

        defaultOptions: {
          loop: false,
          autoplay: false,
          animationData: linkedin,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          },
        },
      }
  	}
  }

  render () {
    if (this.props.isMobile) {
      return <div className="ContactPage">
        <header className="ContactPage-header-mobile">
          <div className="ContactPage-contact-mobile">
            <ContactBtn details={this.state.linkedin}/>
            <ContactBtn details={this.state.github}/>
            <ContactBtn details={this.state.twitter}/>
          </div>
        </header>
      </div>
    } else {
      return <div className="ContactPage">
        <header className="ContactPage-header">
          <div className="ContactPage-contact">
            <ContactBtn details={this.state.linkedin}/>
            <ContactBtn details={this.state.github}/>
            <ContactBtn details={this.state.twitter}/>
          </div>
        </header>
      </div>
    }
  }
}
