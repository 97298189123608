import Lottie from 'react-lottie';
import React from 'react';

export default class ContactBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isStopped: true,
    }
  }

  toggleHover(){
      this.setState({
        isStopped: false
      })
  }

  toggleStop(){
      this.setState({
        isStopped: true
      })
  }

  render() {
    const windowSize = window.screen.height;
    const size = (windowSize * 20)/100;

    return <a
            href={this.props.details.link}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={this.toggleHover.bind(this)}>

          <Lottie options={this.props.details.defaultOptions}
                height={size}
                width={size}
                isStopped={this.state.isStopped}
                eventListeners={[{ eventName: 'complete',
                                  callback: () => this.toggleStop()
                                },]}/>
    </a>
  }
}
